import React, { Fragment } from "react"
import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"

import {
  SectionContainer,
  FaqSectionWrapper,
  FaqWrapper,
  FaqLeftDiv,
  FaqRightDiv,
  FaqPageTitle,
  FaqTextBox,
} from "../../../../../components/Layout/styled"

import Footer from "../../../../../components/Layout/Footer"
import SeoComp from "../../../../../components/SeoComp"
import FaqListSideBar from "../../../../../components/FaqListSideBar"
import FaqListBreadCrumbSection from "../../../../../components/FaqListBreadCrumbSection"
import FaqListPrevNextSection from "../../../../../components/FaqListPrevNextSection"
import FaqReviewSection from "../../../../../components/FaqReviewSection"
import FaqSearchSection from "../../../../../components/Layout/FaqSearchSection"

const FaqSubPage = ({ intl, data }) => {
  const faqStoreUrl = () => {
    let storePath = ""
    if (process.env.GATSBY_ACTIVE_ENV === "eur") {
      if (intl.locale === "fr") {
        storePath = process.env.GATSBY_ACTIVE_ENV
      } else {
        storePath = process.env.GATSBY_ACTIVE_ENV + `/${intl.locale}`
      }
    } else if (process.env.GATSBY_ACTIVE_ENV === "cad") {
      if (intl.locale === "en") {
        storePath = process.env.GATSBY_ACTIVE_ENV
      } else {
        storePath = process.env.GATSBY_ACTIVE_ENV + `/${intl.locale}`
      }
    } else if (process.env.GATSBY_ACTIVE_ENV === "gbp") {
      storePath = process.env.GATSBY_ACTIVE_ENV
    } else if (process.env.GATSBY_ACTIVE_ENV === "usd") {
      storePath = process.env.GATSBY_ACTIVE_ENV
    } else {
      if (intl.locale === "fr") {
        storePath = ``
      } else {
        storePath = `${intl.locale}`
      }
    }
    return storePath
  }
  const getTitle = key => {
    var faqList = ""
    var pageTitle = ""
    faqList =
      data.allDirectusFaqPageTranslation.nodes[0].faq_product_category &&
      data.allDirectusFaqPageTranslation.nodes[0].faq_product_category.list
    pageTitle = faqList.find(list => list.key === key).title
    return pageTitle
  }

  return (
    data &&
    data.allDirectusFaqPageTranslation.nodes.length > 0 &&
    data.allDirectusFaqListTranslation.nodes.length > 0 && (
      <Fragment>
        <SeoComp
          description={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].meta_description
          }
          title={
            data.allDirectusFaqListTranslation.nodes[0] &&
            data.allDirectusFaqListTranslation.nodes[0].title
          }
          twitter_card={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].twitter_card
          }
          image={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].image &&
            data.allDirectusSeoTranslation.nodes[0].image.data &&
            data.allDirectusSeoTranslation.nodes[0].image.data.url
          }
          hreflangPathname={{
            fr:
              "faq/informations-et-conseils-sur-nos-produits/je-souhaite-avoir-des-informations-sur-loop/a-quoi-sert-loop",
            en: "",
            de: "",
            es: "",
          }}
        />
        <SectionContainer>
          <FaqSearchSection />
          <FaqSectionWrapper>
            {data.allDirectusFaqPageTranslation.nodes[0].faq_product_category &&
              data.allDirectusFaqPageTranslation.nodes[0].faq_product_category
                .list &&
              data.allDirectusFaqListTranslation.nodes[0].title && (
                <FaqListBreadCrumbSection
                  data={data.allDirectusFaqPageTranslation.nodes[0]}
                  title={getTitle("loop")}
                  sidebarKey={"information_and_tips_on_our_products"}
                  productCatPage={true}
                  productCatkey={"loop"}
                  productCatTitle={
                    data.allDirectusFaqListTranslation.nodes[0].title
                  }
                />
              )}

            {/* {data.allDirectusFaqPageTranslation.nodes[0].faq_product_category &&
              data.allDirectusFaqPageTranslation.nodes[0].faq_product_category
                .list && (
                <FaqListPrevNextSection
                  data={
                    data.allDirectusFaqPageTranslation.nodes[0]
                      .faq_product_category.list
                  }
                  title={data.allDirectusFaqListTranslation.nodes[0].title}
                  sidebarKey={"loop"}
                />
              )} */}

            <FaqWrapper>
              <FaqLeftDiv>
                {data.allDirectusFaqPageTranslation.nodes[0]
                  .faq_product_category &&
                  data.allDirectusFaqPageTranslation.nodes[0]
                    .faq_product_category.list &&
                  data.allDirectusFaqListTranslation.nodes[0].title && (
                    <FaqListSideBar
                      data={
                        data.allDirectusFaqPageTranslation.nodes[0]
                          .faq_product_category.list
                      }
                      title={data.allDirectusFaqListTranslation.nodes[0].title}
                      sidebarKey={"loop"}
                    />
                  )}
              </FaqLeftDiv>

              <FaqRightDiv>
                {data.allDirectusFaqListTranslation.nodes[0].title && (
                  <FaqPageTitle
                    dangerouslySetInnerHTML={{
                      __html: data.allDirectusFaqListTranslation.nodes[0].title,
                    }}
                  />
                )}

                {data.allDirectusFaqListTranslation.nodes[0].content && (
                  <FaqTextBox
                    dangerouslySetInnerHTML={{
                      __html: data.allDirectusFaqListTranslation.nodes[0].content.replace(
                        "curr_locale",
                        faqStoreUrl()
                      ),
                    }}
                  />
                )}

                {data.allDirectusFaqPageTranslation.nodes[0]
                  .faq_article_useful_title &&
                  data.allDirectusFaqPageTranslation.nodes[0]
                    .faq_feedback_success && (
                    <FaqReviewSection
                      data={data.allDirectusFaqPageTranslation.nodes[0]}
                      title={data.allDirectusFaqListTranslation.nodes[0].title}
                      sidebarKey={"information_and_tips_on_our_products"}
                    />
                  )}
              </FaqRightDiv>
            </FaqWrapper>
          </FaqSectionWrapper>
        </SectionContainer>

        {data.allDirectusFooterTranslation.nodes.length > 0 && (
          <Footer data={data.allDirectusFooterTranslation.nodes[0]} />
        )}
      </Fragment>
    )
  )
}

export const query = graphql`
  query($lang: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allDirectusFaqPageTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        faq_page_title
        faq_search_title
        faq_article_useful_title
        faq_feedback_success
        faq_list {
          list {
            title
            link
            key
            icon
            item_list {
              item_title
              item_link
            }
          }
        }
        faq_product_category {
          list {
            title
            link
            key
            item_list {
              item_title
              item_link
            }
          }
        }
      }
    }
    allDirectusFaqListTranslation(
      filter: {
        faq_list: {
          faq_category: { eq: "information_and_tips_on_our_products" }
          name: { eq: "À quoi sert Loop ?" }
          faq_product_category: { eq: "loop" }
        }
        language: { eq: $lang }
      }
    ) {
      nodes {
        title
        content
      }
    }
    allDirectusFooterTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        facebook
        gbp_facebook
        youtube
        twitter
        instagram
        linkedin
        about_us {
          name
          list {
            link
            list
          }
        }
        contact {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        purchases {
          list {
            list
            link
          }
          name
        }
        help {
          list {
            link
            list
          }
          name
        }
        about_us_menu {
          name
          list {
            link
            list
          }
        }
        knowledge_center_menu {
          name
          list {
            link
            list
          }
        }
        shop_menu {
          name
          list {
            link
            list
          }
        }
        support_menu {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        newsletter_heading
        follow_us_heading
        footer_bottom {
          list {
            link
            list
          }
        }
      }
    }
    allDirectusSeoTranslation(
      filter: { language: { eq: $lang }, seo: { page_name: { eq: "faq" } } }
    ) {
      nodes {
        meta_title
        meta_description
        twitter_card
        twitter_title
        url
        image {
          data {
            url
          }
        }
      }
    }
  }
`

export default injectIntl(FaqSubPage)
